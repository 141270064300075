var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{ref:"notificationMenu",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"ms-0 pa-0",attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"large":"","color":"#707070"}},[_vm._v("mdi-bell-outline")]),(_vm.unreadNotifications.length > 0)?_c('v-badge',{style:({
                        position: 'absolute',
                        top: '5px',
                        right: '12px',
                    }),attrs:{"content":_vm.unreadNotifications.length,"color":"red","overlap":""}}):_vm._e()],1)]}}])},[_c('v-list',{staticClass:"pb-0",staticStyle:{"min-width":"40vw","max-width":"60vw"}},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"py-0 my-0"},[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("Notifications")])],1)],1),(
                    _vm.unreadNotifications.length > 0 &&
                        _vm.unreadNotifications.length != _vm.notifications.length
                )?_c('v-list-item',{staticClass:"text-center"},[_c('v-btn-toggle',{style:({ width: '100%' })},[_c('v-btn',{style:({ width: '33%' }),attrs:{"rounded":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.notificationsToShow = 'all'}}},[_vm._v("All")]),_c('v-btn',{style:({ width: '33%' }),attrs:{"rounded":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.notificationsToShow = 'unread'}}},[_vm._v("Unread")]),_c('v-btn',{style:({ width: '33%' }),attrs:{"rounded":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.notificationsToShow = 'read'}}},[_vm._v("Read")])],1)],1):_vm._e(),(_vm.notifications.length > 0)?_c('v-list-item-group',{style:({ 'max-height': '300px', 'overflow-y': 'auto' })},_vm._l((_vm.notificationsFiltered),function(notification){return _c('div',{key:notification.id},[_c('NotificationCard',{attrs:{"notification":notification,"notificationDocs":_vm.notificationDocs},on:{"openQuote":_vm.openQuote}})],1)}),0):_c('v-list-item',[_c('v-list-item-content',[_vm._v("No notifications to show.")])],1)],1)],1),_c('v-dialog',{attrs:{"retain-focus":false,"fullscreen":""},model:{value:(_vm.openQuoteDialog),callback:function ($$v) {_vm.openQuoteDialog=$$v},expression:"openQuoteDialog"}},[(_vm.openQuoteDialog)?_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"ml-n4"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeQuote}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" Quote ")],1),_c('v-card-text',{staticClass:"px-0",style:({ height: `${this.heightPopUp}px` })},[_c('Quote',{attrs:{"id":_vm.selectedQuote.id,"quote":_vm.selectedQuote,"settings":_vm.settings,"history":!!_vm.selectedQuote.archive,"followUp":true}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }